<template>
  <svg viewBox="100 100 400 400" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M148.497 169.135C150.981 166.013 153.613 163.009 156.245 160.005C156.363 159.856 156.63 159.826 156.748 159.677C156.985 159.38 157.37 159.201 157.606 158.903L157.843 158.606C159.678 156.91 161.63 155.064 163.881 153.456C171.845 147.41 180.11 142.817 188.825 139.795C216.778 129.981 247.894 136.029 272.295 159.065C306.366 191.002 303.315 242.451 276.117 276.647C241.748 327.625 182.684 398.748 264.463 462.46C279.167 473.916 290.075 483.361 336.392 496.746C306.1 502.326 278.012 500.59 246.748 492.605C224.634 480.123 189.866 453.397 178.037 417.3C160.159 362.562 209.513 280.732 233.365 249.216C266.113 205.599 213.124 158.382 174.112 211.095C153.72 238.566 118.044 316.303 130.442 373.965C137.691 406.664 147.353 430.499 185.663 463.241C178.559 459.049 171.66 454.294 164.968 448.974C75.957 366.06 86.2838 237.859 148.497 169.135Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M451.503 430.865C449.019 433.987 446.387 436.991 443.755 439.995C443.637 440.144 443.37 440.174 443.252 440.323C443.015 440.62 442.63 440.799 442.394 441.097L442.157 441.394C440.322 443.09 438.37 444.936 436.119 446.544C428.155 452.59 419.89 457.183 411.175 460.205C383.222 470.019 352.106 463.971 327.705 440.935C293.634 408.998 296.685 357.549 323.883 323.353C358.252 272.375 417.316 201.252 335.537 137.54C320.833 126.084 309.925 116.639 263.608 103.254C293.9 97.6736 321.988 99.4095 353.251 107.395C375.366 119.877 410.134 146.603 421.963 182.7C439.841 237.438 390.487 319.268 366.635 350.784C333.887 394.401 386.876 441.618 425.888 388.905C446.28 361.434 481.956 283.697 469.558 226.035C462.309 193.336 452.647 169.501 414.337 136.759C421.441 140.951 428.34 145.706 435.032 151.026C524.043 233.94 513.716 362.141 451.503 430.865Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="100"
        y1="300"
        x2="500"
        y2="300"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0082FA" />
        <stop offset="1" stop-color="#00F2FE" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="100"
        y1="300"
        x2="500"
        y2="300"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0082FA" />
        <stop offset="1" stop-color="#00F2FE" />
      </linearGradient>
    </defs>
  </svg>
</template>
