<template>
  <footer
    class="h-full lg:h-footer w-full shadow-md flex flex-wrap lg:justify-between border-t"
  >
    <div
      class="flex flex-col sm:flex-row w-full lg:w-auto justify-between sm:justify-around lg:justify-start items-center"
    >
      <LayoutFooterItem class="lg:ml-6 xl:ml-7 text-xs h-auto sm:h-full">
        <span>&copy; {{ new Date().getFullYear() }} Open DeFi Foundation</span>
      </LayoutFooterItem>
    </div>
    <ul
      class="list-footer justify-center flex lg:justify-end flex-wrap lg:flex-no-wrap h-full w-full lg:w-auto"
    >
      <LayoutFooterItem class="lg:mr-6 xl:mr-7">
        <div class="flex">
          <a href="https://twitter.com/InjectiveLabs" class="mr-4">
            <BaseIcon name="social/twitter" md />
          </a>
          <a
            href="https://www.reddit.com/r/injective/"
            target="_blank"
            class="mr-4"
          >
            <BaseIcon name="reddit" md />
          </a>
          <a href="https://discord.gg/injective" target="_blank" class="mr-4">
            <BaseIcon name="social/discord" md />
          </a>
          <a href="https://t.me/joininjective" target="_blank">
            <BaseIcon name="telegram" md />
          </a>
        </div>
      </LayoutFooterItem>
    </ul>
  </footer>
</template>
