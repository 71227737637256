<template>
  <header
    class="relative z-1100 flex-shrink-0 flex h-16 bg-black shadow-top-bar-dark"
  >
    <div class="flex-1 px-2 xl:px-8 flex justify-between">
      <NuxtLink class="flex items-center" :to="{ name: 'index' }">
        <div
          class="flex items-center py-2 pl-2 xl:pl-6 cursor-pointer mr-4 xs:mr-0"
        >
          <AssetLogo class="h-8 w-auto" alt="Injective" />
        </div>
        <div class="border-r h-8 wx-1 mr-6 pl-6 hidden xs:block" />
        <span
          class="leading-4 text-sm uppercase font-semibold tracking-[1.6px]"
        >
          {{ $t('title') }}
        </span>
      </NuxtLink>
    </div>
  </header>
</template>
