<template>
  <div id="pro" class="w-full h-full min-h-screen bg-gray-1050 relative">
    <Transition name="page" appear>
      <div>
        <ClientOnly>
          <div class="relative bg-gray-1050">
            <LayoutTopbar />
            <main class="w-full h-full min-h-screen">
              <div class="relative">
                <NuxtPage />
              </div>
            </main>
            <LayoutFooter />
          </div>
        </ClientOnly>
      </div>
    </Transition>

    <BaseNotifications
      class="z-[1110] fixed inset-0 flex flex-col gap-2 justify-end items-end p-6 pointer-events-none"
    >
      <template #notification="{ notification }">
        <BaseNotification
          :notification="notification"
          class="pointer-events-auto bg-gray-800"
        >
          <template #close="{ close }">
            <BaseIcon
              name="close-bold"
              class="min-w-4 hover:text-blue-500 text-white w-4 h-4"
              @click="close"
            />
          </template>
        </BaseNotification>
      </template>
    </BaseNotifications>
  </div>
</template>
